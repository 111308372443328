import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css" // This only needs to be imported once in your app

import Layout from "../components/layout"
import Seo from "../components/seo"
import ImageView from "../components/imageView"
import useWindowSize from "../hooks/useWindowSize"

// Rendered size:	2258 × 1582 px
// Rendered aspect ratio:	1129∶791
// Intrinsic size:	4744 × 2899 px
// Intrinsic aspect ratio:	4744∶2899

const IndexPage = () => {
  const [isOpen, setOpen] = React.useState(false)
  const windowSize = useWindowSize()
  const renderedImageHeight = windowSize.width * (2899 / 4744)
  if (windowSize.height == undefined) {
    return <></>
  }
  const initialScale =
    renderedImageHeight < windowSize.height
      ? windowSize.height / renderedImageHeight
      : 1
  return (
    <Layout>
      <Seo title="Home" />
      <div className="fixed bottom-0 right-4 md:right-16 z-20 pb-8">
        <ul className="space-y-3 text-center text-base tracking-wide font-semibold uppercase font-semibold">
          <li
            style={{ backgroundColor: "#968f75" }}
            className="px-4 py-1 hover:text-black/70 bg-cover bg-[url('/images/bg.jpg')] hover:bg-none"
          >
            <a href="#" onClick={() => setOpen(true)}>
              View Legend
            </a>
          </li>
        </ul>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc="/images/legend.jpg"
          onCloseRequest={() => setOpen(false)}
        />
      )}
      <ImageView initialScale={initialScale}>
        {/* <img
          src="/images/facilities.jpg"
          className="object-contain min-h-screen h-full min-w-screen w-full"
        /> */}
        <StaticImage
          objectFit="contain"
          className="object-contain md:object-cover min-h-screen h-full"
          src="../../static/images/facilities.jpg"
          alt="Floor plan"
          quality={100}
          breakpoints={[1920]}
          formats={["auto", "webp", "avif"]}
        />
      </ImageView>
    </Layout>
  )
}

export default IndexPage
